import { ExternalInteractorBase, getDistance, isInArray } from "tsparticles-engine";
import { Slow } from "./Options/Classes/Slow";
export class Slower extends ExternalInteractorBase {
    constructor(container) {
        super(container);
    }
    clear(particle, delta, force) {
        if (particle.slow.inRange && !force) {
            return;
        }
        particle.slow.factor = 1;
    }
    init() {
        const container = this.container, slow = container.actualOptions.interactivity.modes.slow;
        if (!slow) {
            return;
        }
        container.retina.slowModeRadius = slow.radius * container.retina.pixelRatio;
    }
    async interact() {
    }
    isEnabled(particle) {
        var _a;
        const container = this.container, mouse = container.interactivity.mouse, events = ((_a = particle === null || particle === void 0 ? void 0 : particle.interactivity) !== null && _a !== void 0 ? _a : container.actualOptions.interactivity).events;
        return events.onHover.enable && !!mouse.position && isInArray("slow", events.onHover.mode);
    }
    loadModeOptions(options, ...sources) {
        if (!options.slow) {
            options.slow = new Slow();
        }
        for (const source of sources) {
            options.slow.load(source === null || source === void 0 ? void 0 : source.slow);
        }
    }
    reset(particle) {
        particle.slow.inRange = false;
        const container = this.container, options = container.actualOptions, mousePos = container.interactivity.mouse.position, radius = container.retina.slowModeRadius, slow = options.interactivity.modes.slow;
        if (!slow || !radius || radius < 0 || !mousePos) {
            return;
        }
        const particlePos = particle.getPosition(), dist = getDistance(mousePos, particlePos), proximityFactor = dist / radius, slowFactor = slow.factor;
        if (dist <= radius) {
            particle.slow.inRange = true;
            particle.slow.factor = proximityFactor / slowFactor;
        }
    }
}
